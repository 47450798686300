<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select coaches to import</label>
      <v-data-table
        class="elevation-2 mt-2 custom-table"
        :class="{ invalid: !isFormValid }"
        checkbox-color="primary"
        :headers="headers"
        disable-pagination
        hide-default-footer
        show-select
        fixed-header
        sort-by="firstName"
        item-key="id"
        :items="coaches"
        v-model="selected"
        height="360"
        :loading="isLoading"
      ></v-data-table>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled || !isValid"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select at least one coach</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { Coach, CoachDetails } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    getPageEvent: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'CoachImporter',
    inject: [
      'setPageTitle',
      'setPageContent',
      'toggleLoading',
      'getPageSport',
      'getPageEvent',
      'goNext',
      'goBack',
      'close',
    ],
    data() {
      return {
        isValid: false,
        opponentSiteUrl: '',
        opponentTenantId: '',
        globalSportId: 0,
        coaches: [] as Coach[],
        selected: [] as Coach[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Title',
            align: 'start',
            value: 'title',
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchCoaches: ClientService.fetchOpponentCoaches,
      fetchClient: ClientService.fetchClient,
      fetchCoachesMembers: ClientService.fetchCoachesMembers,
      fetchScheduleEventInfo: ClientService.fetchScheduleEventInfo,
      fetchGlobalSport: ClientService.fetchGlobalSport,
      fetchOpponentInfo: ClientService.fetchOpponentInfo,
      fetchOpponentRosterBio: ClientService.fetchOpponentRosterBio,
      fetchOpponentCoachBio: ClientService.fetchOpponentCoachBio,
      getOpponentTenantId: ClientService.getOpponentTenantId,
      async importCoaches() {
        let teamInfo = this.program.event.away;
        if (teamInfo.name === '') {
          let pageEvent = this.getPageEvent();
          teamInfo = pageEvent.opponent;
        }

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const sportName = globalSport.shortDisplay;

        const headerText = `${teamInfo.name} ${sportName} Coaches`;
        this.setPageTitle(`${headerText} Import`);

        let coachesMap: CoachDetails[] = [];
        for (const selected of this.selected) {
          const coachBio = await this.fetchOpponentCoachBio({
            tenantId: this.opponentTenantId,
            coachId: selected.id,
          });
          const coach = {} as CoachDetails;
          coach.id = +coachBio.id;
          coach.title = coachBio.title;
          coach.firstName = coachBio.firstName;
          coach.lastName = coachBio.lastName;
          coach.headCoach = coachBio.isHeadCoach ? 'Y' : 'N';
          coach.rosterHeadshot = coachBio.image.absoluteUrl;
          coach.rosterHeadshotAltText = coachBio.image.altText;
          coach.masterImage = coachBio.image.absoluteUrl;
          coach.masterImageAltText = coachBio.image.altText;
          coach.bio = coachBio.bio;
          coachesMap.push(coach);
        }

        const members = sortBy(Object.values(coachesMap), ['lastName', 'firstName']);
        return {
          blocks: [
            {
              id: 1,
              data: { text: headerText },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
              },
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'away',
              },
            },
            {
              id: 3,
              data: {
                members,
              },
              type: 'staff',
              config: {
                title: 'Coaches Panel',
                subtitle: 'Components that build your coaches page. Coach info member cards',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selected.length;
        if (!this.isFormValid || !this.isValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importCoaches();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import coaches data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;

        const { clientId } = this.program;
        const client = await this.fetchClient(clientId);
        const { tenantId } = client;

        this.globalSportId = this.program.sport.globalSportId;
        if (!this.globalSportId) {
          let pageSport = this.getPageSport();
          this.globalSportId = pageSport.globalSportId;
        }

        let scheduleId = this.program.event.scheduleId;
        if (!scheduleId) {
          let pageEvent = this.getPageEvent();
          scheduleId = pageEvent.id;
        }
        const schedule = await this.fetchScheduleEventInfo({ scheduleId, tenantId });
        const {
          opponent: { id },
        } = schedule;

        const opponentInfo = await this.fetchOpponentInfo({ tenantId, id });
        const opponentGlobalId = opponentInfo?.opponentGlobalId;
        const opponentGlobal = await this.getOpponentTenantId(opponentGlobalId.toString());
        this.opponentTenantId = opponentGlobal.client_abbreviation;

        const globalSport = await this.fetchGlobalSport({
          globalSportId: this.globalSportId,
          tenantId: this.opponentTenantId,
        });
        const sportId = globalSport.id;

        const coaches = await this.fetchCoaches({ sportId, tenantId: this.opponentTenantId });
        this.coaches = sortBy(Object.values(coaches), ['lastName', 'firstName']);
        this.isValid = true;
      } catch (error) {
        this.isValid = false;
        this.showToast('Could not fetch coaches data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selected(selected: Coach[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>
